<template>
  <main class="app" :class="{'disable-animation': disableAnimation}">
    <div class="app-logo">
      <a href="/">
        <img src="/img/q-logo.svg?v=1" alt="Logo" class="desktop">
        <img src="/img/cheerios-mobile.svg?v=1" alt="Logo" class="mobile">
      </a>
    </div>
    <Quiz/>
  </main>
</template>

<script>
import Quiz from "./Quiz.vue";

export default {
  components: {
    Quiz
  },
  data() {
    return {
      disableAnimation: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.disableAnimation = true;
    }, 3000);
  }
};
</script>

<style lang="less">
  @import "../assets/less/application";
</style>