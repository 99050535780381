<template>
  <div class="container question-page">
    <transition name="fade" mode="out-in">
      <div class="box" v-if="currentQuestion != null" :key="currentQuestion">
        <div class="question-txt" v-text="currentQuestion.title"></div>
        <div v-if="currentQuestion.img != null" class="question-img">
          <img :src="currentQuestion.img"/>
        </div>
        <div v-if="currentQuestion.description != null" class="question-description">
          <p v-html="currentQuestion.description"></p>
        </div>
        <div class="question-items">
          <div class="question-item" v-for="(answer, i) in currentQuestion.options" :key="i"
               @click="handleAnswer(answer.id)" @mouseover.native="hovered = true" @mouseleave.native="hovered = false">
            <p class="question-item-txt" v-text="answer.title"></p>
          </div>
        </div>
        <div class="bottom-info">
          <div>Spurning {{ questionNumber }} af {{ questions.length }}</div>
        </div>
      </div>
    </transition>
    <modal v-if="currentAnswer != null" name="answer-modal" :adaptive="true" classes="answer-modal" height="auto" reset="true" @closed="goToQuestion(++currentQuestionIndex)">
      <div class="answer-box">
        <div v-if="currentAnswer.img" class="answer-img answer-box-column">
          <img :src="currentAnswer.img" />
        </div>
        <div class="answer-box-column" :class="!currentAnswer.img ? 'no-image' : ''">
          <div>
            <div class="answer-status" v-text="currentAnswer.success ? 'Rétt!' : 'Rangt...'"></div>
            <div class="answer-desc" v-text="currentAnswer.title"></div>
          </div>
          <div class="answer-action">
            <button class="btn" type="button" @click="hideModal()" v-text="questionNumber === questions.length ? 'Niðurstöður' : 'Næsta spurning'"></button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      questions: [],
      currentQuestion: null,
      currentAnswer: null,
      currentQuestionIndex: 0,
      correctAnswersCounter: 0
    }
  },
  created: function () {
    axios.get(window.BASE_URL + '/api/quiz/questions').then((response) => {
      this.questions = response.data.data;
      this.currentQuestion = this.questions[0];
      this.loading = false;
    });
  },
  computed: {
    questionNumber() {
      return this.currentQuestionIndex + 1;
    }
  },
  methods: {
    handleAnswer(optionID) {
      axios.get(window.BASE_URL + '/api/quiz/check-answer', {
        params: {
          questionId: this.currentQuestion.id,
          optionId: optionID
        }
      }).then((response) => {
        this.currentAnswer = response.data.data.answer;
        if (this.currentAnswer.success) {
          this.correctAnswersCounter++;
        }
        this.$nextTick(() => {
          this.$modal.show('answer-modal');
        })
      });
    },
    goToQuestion(questionIndex) {
      this.$modal.hide('answer-modal');
      this.currentAnswer = null;
      if (this.questions.hasOwnProperty(questionIndex)) {
        this.currentQuestion = this.questions[questionIndex];
      } else {
        this.submit();
      }
    },
    hideModal() {
      this.$modal.hide('answer-modal');
    },
    submit() {
      this.$emit('finish', {answeredQuestions: this.correctAnswersCounter, totalQuestions: this.questions.length});
    }
  },
}
</script>