<template>
  <section>
    <Welcome v-if="stage === 'welcome'" v-on:start="onStart()"/>
    <Question v-if="stage === 'question'" v-on:finish="onFinish($event)"/>
    <Result v-if="stage === 'result'" :result="result"/>
  </section>
</template>
<script>
const STAGE_QUESTION = "question";
const STAGE_RESULT = "result";
const STAGE_WELCOME = "welcome";

import Result from "./Result";
import Welcome from "./Welcome";
import Question from "./Question";

export default {
  name: "Quiz",
  components: { Welcome, Result, Question },
  data() {
    return {
      stage: STAGE_WELCOME,
      result: null,
    };
  },
  methods: {
    onStart() {
      this.stage = STAGE_QUESTION;
    },
    onFinish(payload) {
      axios.post(window.BASE_URL + '/api/quiz/finish', {point: payload.answeredQuestions}).then((response) => {
        this.result = response.data.data;
        this.result.totalQuestions = payload.totalQuestions;
      });
      this.stage = STAGE_RESULT;
    }
  }
};
</script>