import Vue from 'vue'
import App from './components/App.vue'
import VModal from 'vue-js-modal'
import VeeValidate from 'vee-validate'
require('./bootstrap');

Vue.config.productionTip = false;

new Vue({
    render: h => h(App),
}).$mount('#app');
Vue.use(VModal);
Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    }
});