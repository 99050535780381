<template>
  <section>
    <div class="container home-page">
      <div class="center-container" >
        <div class="message">Leik lokið!</div>
        <div class="description">
          Það var Hermann Gunnarsson sem stóð uppi sem sigurvegari og hlýtur 300 þúsnd króna gjafabréf hjá Icelandair í verðlaun.
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    start() {
      this.$emit('start', this.answers);
    }
  }
}
</script>