<template>
  <section>
    <div class="container result-page">
      <div class="wrapper center-container" v-if="result">
        <div class="top">
          <div class="summary">Þú svaraðir {{result.point}} af {{result.totalQuestions}} spurningum rétt!</div>
          <div class="detailed" v-text="result.description"></div>
          <div class="actions">
            <button class="btn" @click="share">Deila</button>
          </div>
        </div>
        <div class="bottom">
          <ShareDataForm :resultID="result.id"/>
        </div>
      </div>
      <div v-else>
        <h2>Loading...</h2>
      </div>
    </div>
  </section>
</template>

<script>
import ShareDataForm from "./ShareDataForm";

export default {
  props: {
    result: {
      required: true,
      type: Object
    }
  },
  components: {ShareDataForm},
  methods: {
      share: function () {
        const size = 400
        const config = {
          top: window.innerHeight / 2 - size / 2,
          left: window.innerWidth / 2 - size / 2,
          width: size,
          height: size,
        }
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${this.result.shareUrl}`,
            'Share in Facebook',
            Object.entries(config)
                .map(([k, v]) => `${k}=${v}`)
                .join()
        )
      }
  },
  watch: {
    result: function (value) {
    }
  },
  mounted() {
    const jsConfetti = new JSConfetti()
    jsConfetti.addConfetti()
  }
};
</script>