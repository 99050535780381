<template>
  <section>
    <form @submit.prevent="submit">
      <div class="form" v-if="!ended && !submitted">
        <div class="form-info">
          <div class="form-title">Skráðu þig í pottinn</div>
          <div class="form-desc">Þú gætir unnið 300 þúsund kr. gjafabréf frá Icelandair.</div>
        </div>
        <div class="input-box">
          <div>
            <label for="email">Nafn*</label>
          </div>
          <div>
            <input type="text" id="text" name="name" v-model="form.name" v-validate="'required|min:2'">
            <div class="input-error">{{ errors.first('name') }}</div>
          </div>
        </div>
        <div class="input-box">
          <div>
            <label for="email">Netfang*</label>
          </div>
          <div>
            <input type="email" id="email" name="email" v-model="form.email" v-validate="'required|email'">
            <div class="input-error">{{ errors.first('email') }}</div>
          </div>
        </div>
        <div class="input-box">
          <div>
            <label for="email">Sími*</label>
          </div>
          <div>
            <input type="text" id="phone" name="phone" v-model="form.phone" v-validate="'required|numeric|max:7'">
            <div class="input-error">{{ errors.first('phone') }}</div>
          </div>
        </div>
        <div class="input-box">
          <div></div>
          <div class="form-footer">
            <div class="left-part">
              <input type="checkbox" id="terms-agree" class="css-checkbox" name="terms" v-validate="'required'">
              <label for="terms-agree" :class="errors.has('terms') ? 'error' : ''">Ég samþykki <a href="#" @click="showTerms">skilmála</a></label>
            </div>
            <div class="right-part">
              <button class="btn" type="submit">Skrá mig</button>
            </div>
          </div>
        </div>
      </div>
      <div class="thanks-box" v-if="ended">
        <div class="tb-title">Leik lokið</div>
        <div class="tb-message">Fylgstu með á <a href="https://www.facebook.com/CheeriosIsland" target="_blank">Facebook</a> síðu Cheerios þar sem vinningshafi verður tilkynntur á næstu dögum</div>
      </div>
      <div class="thanks-box" v-else-if="submitted">
        <div class="tb-title">Þitt nafn er komið í skálina!</div>
        <div class="tb-message">Við drögum út heppinn þátttakanda 1. febrúar</div>
      </div>
    </form>
    <modal name="terms-modal" :adaptive="true" classes="terms-modal answer-modal" height="auto">
      <p>
        Einn vinningshafi verður dreginn út fimmtudaginn 1. febrúar í og fær 300.000 kr. gjafabréf frá Icelandair <a href="https://www.icelandair.com/is/adstod/skilmalar-og-skilyrdi/skilmalar-gjafabrefa/" target="_blank">sjá nánar um skilmála gjafabréfa.</a>
        Persónuupplýsingum sem er safnað í tengslum við leikinn eru einungis notaðar við útdrátt og til hafa samband við vinningshafa. <a href=" https://1912.is/stefnur/" target="_blank">Sjá nánar um vinnslu persónuupplýsinga.</a>
      </p>
    </modal>
  </section>
</template>
<script>
export default {
  props: {
    resultID: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      submitted: false,
      ended: false,
      form: {
        email: null,
        name: "",
        phone: "",
        result_id: this.resultID
      },
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post(window.BASE_URL + '/api/quiz/share-data', this.form).then((response) => {
            if (response.status == 200) {
              this.submitted = true;
            }
          });
        }
      });
    },
    showTerms() {
      this.$modal.show('terms-modal');
    }
  },
  created() {
    const dict = {
      messages:{
        required: () => 'Nauðsynlegur reitur',
        email: () => 'Verður að vera tölvupóstur',
        numeric: () => 'Aðeins tölur',
        max: (value, [limit]) => `Verður að vera ${limit} stafir`,
        min: (value, [limit]) => `Verður að vera ${limit} stafir`
      }
    };

    this.$validator.localize('en', dict);
  }
};
</script>